.wsp-posts-list li:first-child, .wsp-posts-list li:first-child ul li {
    display: block;
}

.wsp-posts-list li:not(:first-child) {
    display: none;
}

//  .wsp-posts-list li::nth-child(2) ul li {
//     display: block;
// }

// .wsp-posts-list li:not(:nth-child(2)) {
//     display: none;
// }


.wsp-container {
	padding-left: 40px;
}

.wsp-pages-list {
	margin-bottom: 0 !important;
}

.wsp-pages-list .children {
	margin-bottom: 0 !important;

	li  {
		list-style-type: circle;
	    display: list-item;
	    margin-left: 40px;
	    padding-left: 0;
		&:after, &:before {
			display: none;
		}
	}
}

.wsp-pages-list {
	li {
		list-style-type: disc;
	}
	a {
		color: $color-text !important;
		font-weight: 400 !important;
		line-height: 24px !important;

		&:hover {
			color: $main-color-hover !important;
		}

		&:after, &:before {
			display: none;
		}
		
	}
}

.wsp-posts-list {
	
	a {
		color: $color-text !important;
		font-weight: 400 !important;
		line-height: 24px !important;

		&:hover {
			color: $main-color-hover !important;
		}

		&:after, &:before {
			display: none;
		}
		
	}
}

.wsp-posts-list li:first-child {
	display: list-item !important;

	&:before {
		display: none !important;
	}

	&:first-child {
		padding: 0;
	}

	.wsp-category-title {
		display: list-item !important;
		list-style-type: disc;
	}

	ul li {
		display: list-item !important;
		margin-left: 40px !important;
		padding-left: 0 !important;
		&:before {
			display: none !important;
		}

		list-style-type: circle;
	}

}

@media (max-width: 767px) {
	.wsp-container {
		padding-left: 0;

		.wsp-pages-list, .wsp-posts-list {
			margin-left: 15px;
			padding-left: 0;

			ul {
				padding-left: 0;
			}

			.wsp-posts-list {
				margin-left: 0;
			}
		}
	}
}