.lenta_content {
	height: 280px;
	margin-bottom: 30px;
	.new {
		width: 100%;
		
		text-decoration: none;
		font-size: calcFluid(16, 18, 320, 1920);
		font-weight: bold;
		color: $main-color-hover;

		img {
			margin-bottom: 16px;
		    width: 100% !important;
		    max-height: 210px;
		    object-fit: cover;
		    border: 1px solid transparent;
		    // height: 90% !important;
		}
	}

	:hover {
		img {
			border: 1px solid $caption-color;
		}
		
		color: $caption-color;
	}
}

#true_loadmore { 
	height: 48px;
    padding: 12px 25px;
    border: 1px solid #000;
    width: 255px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    white-space: nowrap;
    transition-duration: $transition;
    margin: auto;

    &:hover {
    	background-color: #1e1a1a;
    	border-color: #1e1a1a;
    	color: #fff;
    	transition-duration: $transition;
    }
}

@media (max-width: 992px) {
	.lenta_content {
		height: 100%;
		padding: 0;
		padding-bottom: 15px;
		margin-bottom: 0;

		.new {
			img {
				height: 70%;
			}
		}
	}

	.lenta {
		margin-bottom: 24px;
	}

	// .lenta {
	// 	margin-bottom: 0;
	// }
}

@media (max-width: 576px) {
	.lenta_content .new img {
		height: 150px;
		margin-bottom: 10px;
	}
}