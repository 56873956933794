.mobile-menu {
	display: none;

	background-color: #faf3f3;
    padding: 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .navbar {
    	&__list {
    		display: none;
    	}
    }

    .navbar {
    	&__list { 
	        margin: 0 auto; 

	        li {
	            padding: 6px 8px;

	            a {
	                text-decoration: none;
	                color: $color-text;
	                font-size: 1.1rem;

	                &:hover{
	                    color:$main-color-hover;
	                }
	            }
	        }

	        .active>a {
	            color:$main-color-hover;
	        }
	    }

	    &__language {
	        display: none;
	    }
    }

    .container {
    	width: 100%;
    }
}


.icon_burger {
	width: 24px;
	height: 24px;
}

.menu_mobile { 
	&_icon_btn {
		display: flex;
		padding: 4px 12px;
		border: 2px solid transparent;
		background-color: #faf3f3;

		&:focus {
			border: 2px solid rgb(229, 151, 0);
			border-radius: 5px;
		}
	}

	&_icon {
		display: flex;
	    justify-content: flex-end;
	}
	
}


.open {
	.navbar {
    	&__list {
    		display: block;
    	}

    	&__language {
	        display: block;
			position: absolute;
			@media(max-width: 575px) {
    			right: 15px;
    		}
    		right: 15%;
    		z-index: 55;
			
	        

	        svg {
	        	width: 25px;
	        	height: 25px;
	        }
	    } 
    }
}

@media(max-width: 1199px) {
	.mobile-menu-open {
		display: flex;
	}
}

@media(max-width: 766px) {
	.mobile-menu {
		display: flex;
	}

	.open {
		.navbar {
	    	&__list {
	    		display: flex;
	    		flex-direction: column;

	    		li {
	    			display: flex;
		        	justify-content: center;
	    		}
	    	}
	    }
	}
}