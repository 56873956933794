.section_about {
    margin-bottom: 0 !important;

    &-head {
    	margin-top: 32px;
	    text-align: center;
	    overflow-x: hidden;
	    margin-bottom: 36px;
    }

    h3 {
    	font-size: 16px;
	    line-height: 24px;
	    font-weight: 500;
	    margin: 16px 0;
	    color: #1e1a1a;
    }

    .img_volex_block {
	    max-height: 210px;
	    overflow: hidden;
	    margin-bottom: 30px;
	    width: 100%;
		object-fit: cover;
		object-position: top
	}

	h2 {
		font-size: 20px;
	    line-height: 26px;
	    color: #1e1a1a;
	    margin-bottom: 16px;
	}

	p {
		font-weight: 200;
		line-height: 24px;
	}
}

.title_red {
	color: #ec2d2d;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin: 16px 0;
    line-height: 24px;
    padding: 8px 0;
}

p.red_number {
	color: $caption-color;
    font-size: 72px;
    line-height: 1;
    font-weight: 500;
}

p.red_text {
	color: $caption-color;
    font-weight: 200;
    margin-bottom: 16px;
}

.red_description {
	font-size: 14px;
	font-weight: 200;
	line-height: 19px;

	span {
		letter-spacing: 2px;
	}
}

.text {
	&_title {
		font-size: 20px;
	    line-height: 26px;
	    color: #1e1a1a;
	    margin-bottom: 16px;
	}

	&_text {
		font-weight: 200;
		line-height: 24px;
	}
}

.about_text_block {
	margin-bottom: 48px;
}

@media (max-width: 992px ) {
	.section_about {
	    .img_volex_block {
		    height: 100%;

		    img {
		    	height: 100%;
		    	object-fit: cover;
		    	width: 100%;
		    }
		}
	}

	.burn_block {
		margin-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.section_about {
		margin-top: 32px;
	}
}

@media (max-width: 575px) {
	.burn {
		order: -1;
	}
	.about_text_block {
		margin-bottom: 16px;
	}

	.img_volex_block {
		height: auto !important;
	}

	
}

