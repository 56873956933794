.category-one {
	height: 100%;
}

.brand {
	margin-bottom: 72px;
}
.page-service-data__list {
	.sidebar {
		ul {
			display: none;

			a {
				color: $color-text;

				&:hover {
					color: $caption-color;
				}
			}
		}
	}
}

.brand-page-list {
	.section__head {
		margin-bottom: 8px;
	}
}


.page-brand-data__block {
	padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:not(:last-child){
    	border-bottom: 1px solid #e1d7d7;
    }
    

	ul {
		display: block !important;
		column-count: 3;
    	column-gap: 30px;
	}

	.letter {
		font-weight: bold;
	    text-transform: uppercase;
	    font-size: 50px;
	    color: #010101;
	}
}

@media (max-width: 767px) {
	.page-service-data__list {
		text-align: center;
	}
}

.category {

    padding-bottom: 30px;
	height: 100%;

	&_item {
		width: 100%;
	    height: 100%;
	    min-height: 100px;
	    display: block;
	    position: relative;
	    border: 1px solid transparent;

	    &::before {
		    content: "";
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 60%);
		}

		&:hover::before {
			display: none;
		}

		&:hover {
			border: 1px solid $caption-color;

			.category_title {
				display: none;
			}
		}
	}

	&_img {
		width: 100%;
    	object-fit: cover;
		height: 100%;
	}

	&_title {
		position: absolute;
	    bottom: 40px;
	    left: 0;
	    right: 0;
	    text-align: center;
	    font-size: calcFluid(16, 20, 320, 1920);
	    font-weight: 500;
	    letter-spacing: 4px;
	    line-height:  calcFluid(19, 26, 320, 1920);
	    text-transform: uppercase;
	    color: #fff;
	}
}

.info {
	p:last-child {
		font-size: 14px;
	    font-weight: 200;
	    line-height: 19px;
	}
	
}

.column_category {
	display: flex;
	flex-wrap: wrap;
}

.nav_categories {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 24px;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;

	&.en {
		justify-content: center;
	}
}

.cat {
    a {
    	margin-right: 12px;
    }

    &_tags {
    	color: $color-text;
    	line-height: 24px;

    	&:hover {
    		color: $main-color-hover;
    	}
    }
}

a.icon_link.active {
	.brand_icon_svg {
		fill: #ec2d2d;
		stroke: #ec2d2d;
	}
}

.brand {
	&_link {
		height: 260px; 
		width: 100%;
		padding: 20px 40px;
		display: block;
		border: 1px solid #e1d7d7;
		text-align: center;
		margin-bottom: 30px;
		position: relative;

		&:hover .hvr {
			visibility: visible;
		}
	}

	&_image {
		height: 100%;
		object-fit: contain;
	}
	&_icon_svg {
		width: 40px;
		height: 40px;
		padding: 8px;

	}
	
}

.hvr {
	position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;

    &:hover {
    	visibility: visible;
    }

	&__images {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__sectors {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
	}

	&__sector {
		flex-grow: 1;
	}
}

.swiper-wrapper {
    height: 100%;
    overflow: hidden;
}

.swiper_img {
	height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    background-color: #fff;
}

@media (min-width: 1199px) {
	.item {
		&-2 {
			height: 230px !important;
		}
	}
}

@media (max-width: 1199px) {
	.category_img {
		height: 100% !important;
	}
	
	.category_title {
		letter-spacing: 2px;
	}
	
}

@media (min-width: 992px) {
	.item {
		&-3 {
			max-height: 100px;
		}
		&-4 {
			max-height: 100px;
		}
	}
	
}

@media (max-width: 992px) {
	.category-one {
		padding-bottom: 30px;
	}
	
	.category_item {
		height: 250px;
	}
}

@media (max-width: 767px) {
	.info {
		display: none;
	}

	.brand {
		margin-bottom: 0;
	}

	.category-one, .category {
		padding-bottom: 15px;
	}

	.hvr {
		display: none;
	}

	.brand_link {
		height: 240px;
		margin-bottom: 15px;
	}
}

@media (max-width: 575px) {
	.category_item {
		height: 250px !important;
	}
	
}
