.tabs-nav {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	margin: 0;
	padding: 0;
	width: 100%;
    border-top: 1px solid #ec2d2d;
}
.tabs-nav li {
    // flex:1 1 auto;
	margin: 0;
	padding: 0;
}    
.tabs-nav a {
	display: block;
	padding: 35px 10px 6.4px 10px; 
	font-size: 16px;
	text-decoration: none;
	color: #000;
    position: relative;
}	

.advantages_title {
    white-space: nowrap;
    font-size: 52px;
    font-weight: 500;
    letter-spacing: 4px;
    line-height: 52px;
    text-transform: uppercase;
}

.tabs-nav a.active {
	color: #ec2d2d;
}    
.tabs-items {
    margin-bottom: 24px;
    min-height: 480px;
    height: 100%;
}

.tabs-item {
    transition: opacity .15s linear;
    animation-name: Appearance;
    animation-duration: .15s;
        

    @keyframes Appearance {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    &.visible-tab {
        animation-name: App;
        animation-duration: .15s;
    

        @keyframes App {
          0% {opacity: 0;}
          100% {opacity: 1;}
        } 
    }
}

.content_advantages {
    min-height: 450px;
    background-size: cover;
    background-position: center center;
    padding-bottom: 16px;
    height: 100%;
}

.content_advan {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content_advan .content_advantages {
    height: 100%;
    min-height: 200px;
    flex-grow: 1;
}
.tabs-nav a.active::after {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
}

.tabs-nav a.active::before {
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    border-top: 21px solid #ec2d2d;
}

.tabs-nav a.active::before, .tabs-nav a.active::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 40px;
    margin: auto;
    width: 0;
    height: 0;
}

.text_block {
    margin-bottom: 16px;
}

@media (max-width: 1199px ) {
    .tabs-nav a {
        padding: 0;
        padding-top: 35px;
    }
}


@media (max-width: 992px ) {

    .tabs-items {
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    .advantages_description {
        white-space: nowrap;
        font-size: 14px;
    }

    .advantages_title {
        font-size: 32px;
        letter-spacing: 1px;
        line-height: 1;
    }

    .tabs-item .row div:nth-child(3n) .one_block{
        display: none;
    }

    .tabs-item .row div:nth-child(3n) .row .text {
        margin-top: 30px;
    }
}

@media (max-width: 767px ) {
    .content_advantages {
        padding-bottom: 30px;
        width: 100%;
    }

    .content_advan {
        padding-bottom: 10px;
    }

    .one_block {
        display: none;
    }

    .tabs-item .row div:nth-child(3n) .row .text {
        margin-top: 10px;
    }
}

@media (max-width: 775px ) {
    .tabs-nav {
        display: none;
    }
}

