.backgroung-paragrah {
	width: 100%;
    height: 100%;
    padding: 0 30px;
    min-height: 200px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #e1d7d7;
    position: relative;
    &:before {
    	content: '';
    	position: absolute;
    	top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-color: rgba(255, 255, 255, 0.9);
	    z-index: 20;
    }
}

.image-paragrah {
	width: 100%;
    height: 100%;
    padding: 0 30px;
    min-height: 200px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #e1d7d7;
}

.logo_paragrah {
	position: absolute;
	top: 40%;
	 z-index: 30;
}

.paragrah_section {
	&:not(:first-child){
		padding-top: 30px;
	}
	
    padding-bottom: 30px;

	.section__head {
		margin-top: 0;
	}

	p {
		line-height: 24px;
        margin-bottom: 16px;
	}
}

.card_wrap {
    border: 1px solid #ec2d2d;
    padding: 15px;
    height: 100%;
    font-size: 14px;
    justify-content: center;
	flex-direction: column;
	display: flex;

    p {
    	line-height: 21px;

    	strong {
    		color: $main-color-hover;
    	}
    }
}

.paragrah_text-add {
	font-size: 14px;
	line-height: 21px;
	height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    strong {
    	display: block;
    	margin-bottom: 8px;
    	font-size: 16px;
    }
}

.paragrah-border:not(:last-child) {
	border-bottom: 1px solid #e1d7d7;	
}

.section-buyers {
	margin-bottom: 44px;
}
