@import 'section_about';
@import 'section_title';
@import 'section_advantages';
@import 'section-category';
@import 'page-lenta';
@import 'page-buy';
@import 'sitemap-page';
@import 'test-scroll';


h2 {
	font-size: 20px;
	line-height: 26px;
    color: #1e1a1a;
    margin-bottom: 16px;
}

.text_block {
	p {
	    line-height: 24px;

		img {
			height: 100%;
		    min-height: 200px;
		    flex-grow: 1;
		    max-height: 210px;
    		object-fit: cover;
		}
	}
}

.section_video_banner {
	display: block;
	overflow: hidden;
	height: 100%;
	position: relative;
}

.section_video_banner.display {
	position: relative;
	height: 500px;
}

video {
	display: block;    
}

.video_wrap {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
    	position: absolute;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    margin: auto;
	    width: auto;
	    min-width: 100%;
	    min-height: 100%;
	    z-index: 0;
	    min-height: 750px;
    }
}

.play .video_wrap {
    height: 750px;
}

.play .video_wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 650px;
}

.play .section_video_banner {
	position: static;
	overflow: hidden;
}

.video_button-play {
	color: $main-color-hover;
    line-height: 48px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    border: 1px solid #716868;
    border-radius: 50%;
    text-align: center;

    &:before {
	    display: block;
	    padding-left: 4px;
	    transform: scaleX(0.8);
	}

	&:hover {
		color: $main-color-hover;
		border: 1px solid #1e1a1a;;
	}
}

.section {
	margin-bottom: 36px;
	opacity: 0;
    transform: translateY(20%);
}

.section.element-show {
    opacity: 1;
    transition: all 1.5s;
    transform: translateY(0%);
}

.section_video_banner {
	margin-bottom: 0;
}

.video_preview {
	position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    background-repeat: no-repeat;
    background-position: 97.5% 10%;
    background-size: 150px;

    &-title {
    	font-size: 48px;
	    letter-spacing: 6px;
	    padding: 0 15px;
	    font-weight: 500;
	    color: $main-color-hover;
	    text-transform: uppercase;
	    margin-bottom: 58px;
	    text-align: center;
    }

    &-subtitle {
    	font-size: 22px;
	    letter-spacing: 2px;
	    padding-left: 15px;
	    padding-right: 15px;
	    margin-bottom: 48px;
	    text-transform: uppercase;
	    text-align: center;
	    line-height: 33px;
    }
}

.play .video_preview {
	z-index: 0;
}

.news {
	display: block;

	&_img {
		border: 2px solid transparent;
	}
	
	&_title{
		color: $main-color-hover;
		margin-top: 16px;
		font-size: 18px;
	    font-weight: 700;
	    line-height: 27px;
	}

	&:hover img {
		border: 2px solid $caption-color;
	}

	&:hover .news_title {
		color: $caption-color;
	}	
}

.btn_more-news {
	font-weight: 500;
	margin: 0 auto;
	padding: 12px 25px;
	border: 1px solid $main-color-hover;
	text-transform: uppercase;
    letter-spacing: 2px;
    font-size: calcFluid(14, 16, 320, 1920);
    color: $main-color-hover;
    transition-duration: $transition;

    &:hover {
    	background: $main-color-hover;
    	color: #fff;
    	transition-duration: $transition;
    }

}

.btn_more {
	display: flex;
	justify-content: center;
}

.partner_logo {
	display: flex;
	justify-content: center;
	margin-bottom: 5px;

	img {
		height: 125px;
		object-fit: contain;
	}
}


@media (max-width: 767px) {
	.section_video_banner {
		display: none;
	}

	.section_advantages {
		margin-bottom: 0;
	}

	.section {
		opacity: 0;
		transform: translateY(0);
	}
}

@media (max-width: 575px) {
	.section {
		margin-bottom: 44px;
	}

	.section_advantages {
		margin-bottom: 0;
	}

}

@media (max-width: 991px) {
	.video_preview {
		background: rgba(255, 255, 255, 0.9) !important;
	}
}
