@import 'includes/variables';
@import 'includes/mixins';

@import './general';
@import './post';




// styles

@import 'components/common/header';
@import 'components/common/mobile-menu';
@import 'components/common/footer';
@import 'components/common/modal';



@import 'components/pages/index';
@import 'components/pages/page-404';
@import 'components/pages/page-contacts';
@import 'components/pages/page-search';




