.page-wrapper {
	overflow: hidden;
}

.cbp-so-init {
	opacity: 0;
}

.cbp-so-init  {
	transform: translateX(-80px);
}

.section.cbp-so-animate {
	transform: translateX(0px);
	opacity: 1;
}