.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    overflow: hidden auto;
    opacity: 0;
    z-index: -1;
    transition-duration: 1s;

    &__wrapper {
        width: 85%;
        padding: 15px 20px;
        margin: 0 auto;
        background-color: #fefefe;
        font-family: Tahoma;
        border: 1px solid #383838;
        border-radius: 4px;
        min-width: 320px;
        max-width: 600px;
        position: absolute;
        left: 0;
        right: 0;
        transition-duration: 1s;
        top: 100%;
        box-shadow: 0 15px 20px rgba(0,0,0,.22),0 19px 60px rgba(0,0,0,.3);
    }

    &-form {
        display: flex;
        flex-direction: column;

        .form_input {
            margin-bottom: 16px;
            padding: 8px 12px;
            line-height: 20px;
            border: 1px solid rgba(0,0,0,.15);
            color: #495057;
        }
    }
}


.modal-consent {
	font-size: 11px;
    line-height: 11px;
    text-align: center;

    a {
        color: inherit;

        &:hover {
            color: $main-color-hover;
        }
    }
}

.custom-modal-header {
	display: flex;
    justify-content: space-between;
    background-color: #fefefe;
    margin-bottom: 24px;
    p {
        text-align: center;
        margin: 0 auto;
        font-size: 20px;
        color: $main-color-hover;
        font-weight: 500;
    }

    svg {
        width: 12px; 
        height: 12px;
        fill: #333 !important;
        text-align: left;
    }
}

.button__submit {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    background-color: $main-color-hover;
    transition-duration: $transition;
    padding: .75rem 1.6rem;
    margin: 8px auto 16px;
    display: inline-block;
    width: auto !important;
    height: auto !important;
    font-family: Tahoma;

    &:hover {
        color: #fff;
        background-color: $caption-color;
        border-color: $caption-color;
        transition-duration: $transition;
        outline: none;
        border: 0;
    }
}


.form-request {

    $request: & !global;

    &__response{

        position: absolute;

        width: 100%;
        height: 100%;

        opacity: 0;
        visibility: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-wrap{
            border-radius: 15px;
            height: 100%;
            top: 5px;
            z-index: -1;
            position: fixed;     
            width: 20%; 
            right: 0; 

            .response_form {
                position: absolute;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 250px;
                padding: 10px;
                font-size: 12px; 
                line-height: 18px;  
                border-radius: 5px;
                opacity: 0.7;
                right: 0;

            }

            .form-error {   
                display: none; 
                background: red;
            }

            .form-success {  
                display: none;  
                background: green;
            }

            &_active{
                z-index: 1;
            }

        }

        &-success{

        }
        
        &-error{

        }

        &_active{

            opacity: 1;
            visibility: visible;
            position: relative;

        }

    }

    &__header {
        
    }

    &__group{

        &:not(:last-child){

            margin-bottom: 50px;

            @include _small {
                
                margin-bottom: 25px;

            }

        }

        &-title{

            display: block;

            font-style: normal;
            font-weight: bold;

            font-size: 18px;
            line-height: 24px;

            margin-bottom: 25px;

        }

        &-wrapper{

            display: grid;

            grid-template-columns: repeat(2, 1fr);

            row-gap: 50px;
            
            @include _small_ {
                column-gap: 35px;
            }

            @include _small {
                
                grid-template-columns: 1fr;

                row-gap: 30px;
                
            }
            
        }

    }

    &__textarea{

        padding-top: 3px;

    }

    &__field {

        position: relative;

        padding: 8px 12px;

        cursor: pointer;

    }

    &__label {

        display: block;
        position: relative;

        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        height: 100%;
        margin-bottom: 0;

        &.active{

            #{$request}{

                &__text {

                    transform: translateY(-20px);

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;

                    color: rgba(255, 255, 255, 0.8);

                    @include _small {

                        transform: translateY(-18px);
                        font-size: 12px;
                        
                    }

                }
            
            }

        }

    }

    &__text {

        position: absolute;

        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 0;

        color: rgba(255, 255, 255, 0.6);

        font-style: normal;
        font-weight: normal;

        top: -2px;

        @include _Extra_large_ {

            font-size: 16px;
            line-height: 22px;
            
        }

        transition: all ease-in 400ms;

        &_required{

            &:after{

                content: "";
    
                display: inline-block;
                width: 6px;
                height: 6px;
    
                margin: 0 0.3em;
                vertical-align: super;
    
                background-repeat: no-repeat;
    
                background-size: 6px 6px;
            }
        }
    }

    &__input {

        cursor: pointer;

        width: 100%;
        height: 100%;

        border: none;
        
        margin: 0;

        background: transparent;

        color: #fff;

        border-radius: 2px;

        @include _Extra_large_ {

            font-size: 16px;
            line-height: 20px;

        }

        &-count{

            display: none;

        }

    }

    &-select{

        $select:&;

        &__value{

            display: none;

        }

        &__text{

            opacity: 0;

            display: flex;
            align-items: center;

        }

        &__wrapper{

            position: absolute;

            background-color: #1A2533;

            transition: all ease 400ms;

            width: 100%;
            top: calc(100% + 1px);

            z-index: 4;

            visibility: hidden;
            opacity: 0;

        }

        &__svg{

            position: absolute;

            transition: all ease 400ms;

            width:12px;
            height:7px;

            right: 0px;
            bottom: 50%;
            transform: translateY(50%);

            path{

                stroke: #fff;

            }

        }

        &_active{

            #{$select}{

                &__wrapper{

                    opacity: 1;
                    visibility: visible;

                }

                &__text{
                    opacity: 1;
                }

                &__svg{

                    transform: rotate(180deg);

                }
        
            }

        }

        &_ready{

            #{$select}{
                
                &__text{
                    opacity: 1;
                }

            }


        }

        #{$request}{

            &__svg{

                pointer-events: none;

            }

            &__input{

                pointer-events: none;

            }

            &__text{

                pointer-events: none;

            }
        
        }

    }

    &__footer {
        text-align: center;
        
    }

    &__btn {
        width: 100%;
        height: 60px;

        @include _small {

            font-size: 14px;
            line-height: 22px;
            font-weight: bold;

            height: 40px;
        }

    }

    &__confirm {

        font-style: normal;
        font-weight: normal;

        font-size: 13px;
        line-height: 17px;

        color: rgba(255, 255, 255, 0.5);

    }

    &__person {
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.5);
    }

    .valid-error{

        .form-request__input::placeholder {
            color: #DC472E !important;
        }
        

        #{ $request } {

            &__text {
                color: #DC472E;
            }

            &__input{
                border-color: #DC472E;
            }
        }
    }
}

.form-modal{

    display: flex;          
    flex-direction: column;

    // position: relative;

    width: 100%;
    color: #fff;

    #{$request}{

        &__header{

            display: flex;
            flex-direction: column;

            margin-bottom: 24px;

            & > * {

                &:not(:last-child){

                    margin-bottom: 1rem;

                }

            }

        }

        &__field{

            border: 1px solid rgba(0,0,0,.15);

        }

        &__text,&__input{
            

            color: #495057;

        }



    }

}

@media(max-width: 1199px) {
    html {
        padding-right: 0 !important;
    }

}


@media(max-width: 575px) {
    .form-request__field {
        padding: 5px 12px;

        label {
            margin-bottom: 0;
        }
    }
}