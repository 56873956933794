.footer-top {
    padding: 24px 0;
    background-color: #faf3f3;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;


    .title_menu {
        font-size: 14px;
        color: $main-color-hover;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .footer__menu-item {
    	display: flex;
    	flex-wrap: wrap;

    	li {
    		line-height: 22px;
    		width: 100%;
    	}

        a {
            text-decoration: none;
            color: $color-text;
            font-size: 14px;

            &:hover{
                color:$main-color-hover;
            }
        }
        
    }

    .footer_contact{
        li {
            line-height: 22px;
        }
        a {
            text-decoration: none;
            color: $color-text;
            font-size: 14px;

            &.footer_contact-email {
                padding-bottom: 3px; 
                border-bottom: 1px solid $border-color;

                &:hover{
                    color:$main-color-hover;
                    border-bottom: 1px solid $main-color-hover;
                }
            }

            &:hover{
                color:$main-color-hover;
            }
        } 
    }

    .footer_socia {
        a {
            color: $color-text;
            font-size: 28px;
            margin-right: 20px;
            margin-bottom: 10px;
        }

        .vk:hover {
            color:#4a76a8;
        }
        .instagram:hover {
            color: #e03b64;
        }
        .facebook:hover {
            color:#3b5998;
        }
        .twitter:hover {
            color:#1e1a1a;
        }
    }
}

.footer-bottom {
    border-top: 1px solid #C4C4C4;
    background-color: $main-color-hover;
    &__wrap {
        display: flex;
        align-items: center;
        height: 63px;
    }

    &__text {
        font-size: 14px;
        line-height: 17px;
        color: $color-text;
        &:not(:first-child) {
            margin-left: 40px;
        }
        &.link {
            transition: .3s;
            &:hover {
                color: $main-color;
            }
        }
    }
}

.logo-footer {
    margin: 0 auto;

    &__svg {
        width: 120px;
        height: 51px;
        fill: #fff;
    }
}

.made-by {
    display: flex;
    align-items: center;
    white-space: nowrap;
    $this:&;
    &__text {
        font-size: 14px;
        line-height: 17px;
        color: $color-text;
        margin-right: 7px;
        transition: .3s;
    }
    &__svg {
        width: 100px;
        height: 21px;
        fill: $color-text;
        transition: .3s;
    }
    &:hover {
        #{ $this }__text {
            color: #ec2d2d;
        }
        #{ $this }__svg {
            fill: #ec2d2d;
        }
    }
}

.button__callback {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff !important;
    font-weight: 500; 
    background-color: $main-color-hover;
    transition-duration: $transition;
    display: inline-block;
    padding: .75rem 1.6rem;
    text-align: center;
    white-space: nowrap;
    font-size: calcFluid(14, 16, 320, 1920);

    &:hover {
        background-color: $caption-color;
        border-color: $caption-color;
        transition-duration: $transition;
        color: #fff !important;
    }
}

.made_by-gik {
    color: #faf3f3;
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px solid;
    transition: $transition;

    &:hover {
        border-bottom: 1px solid transparent;
        transition: $transition;
    }

    span {
        color: #17a2b8;
        font-weight: 500;
    }
}

@media(min-width: 767px) {
    .footer-top .footer__menu-item {
    	li {
    		width: 50%;
    	}
    }
    
}

@media(max-width: 992px) {
	
    .menu {
        margin-bottom: 16px;
    }
    
    .last {
        text-align: center;
    }
    
}

@media(max-width: 766px) {
	.footer-top {
		text-align: center;
        padding-bottom: 0;
	}

    .footer-bottom {
        height: 113px;
        padding: 10px 0 8px;

        &__wrap {
            flex-direction: column;
        }
    }

    .button__callback { 
        margin-bottom: 30px;
    }
}
