/*  fonts
---------------------------------------*/
@import './fonts/stylesheet.css';


/*	Reset
---------------------------------------*/
*  {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
*:focus, *:focus *{
	box-shadow: none !important;
	outline: none !important;
}
.custom-control-input:focus~.custom-control-label::before{
	box-shadow: none !important;
}
html {
	min-height: 100%;
	font-size: 16px;
	height: 100%;
	&.opened{
		overflow: hidden;
		position: fixed;
	}
}
body {
	min-width: 320px;
	height: 100%;
	margin: 0;
	font-family: "GothamPro";
	font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #716868;
    overflow: hidden;
    &.header_loaded {
    	overflow: auto;
    }
	&.opened {
		overflow: hidden;
		height: 100%;
	}
}
main {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	overflow: hidden;
	background-position: center top;
	width: 100%;
}

ol, ul  { 
	padding: 0;
	margin: 0;
}
li  {
	list-style-type: none;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4, p  {
	line-height: 26px;
	margin: 0;
	padding: 0;
}
a:focus, button:focus, input:focus, textarea:focus  {
	outline: 0;
	text-decoration: none;
}
a  {
	text-decoration: none;
	&:hover  {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}



// General styles
.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.container {
	// width: calcFluid(1100, 1200, 1200, 1920);
	// max-width: 1140px;
	// width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}


@media (min-width: 1200px) {
	.container {
	    max-width: 1140px;
	}
}
