
.contacts-wrapper {
	display: flex;
	justify-content: space-between;

	&:not(:last-child) {
		margin-bottom: 30px;
	}
}

.map {
    width: 100%;
}

.contacts {
	width: calcFluid(290, 345, 767, 1920);
	max-width: 100%;
	flex-shrink: 0;
	margin-right: calcFluid(30, 50, 767, 1920);
	&__title {
		font-weight: 600;
		font-size: calcFluid(17, 24, 320, 1920);
		line-height: calcFluid(22, 30, 320, 1920);
		color: #333;
		display: block;
		margin-bottom: calcFluid(15, 30, 320, 1920);
	}
}

.contact {
	&:not(:last-child) {
		margin-right: 15px;
	}
	&__title {
		line-height: calcFluid(18, 24, 320, 1920);
		color: $main-color-hover;
		font-weight: 500;
		font-size: 16px;
		display: block;
		margin-bottom: 8px;
	}
	&__value {
		display: flex;
		align-items: center;
	}
	&__text {
		font-size: 15px;
		line-height: 24px;
		color: $color-text;
		font-weight: 200;
		&:not(:last-child) {
			margin-right: calcFluid(10, 20, 320, 1920);
		}
		&.link {
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
		}
	}
}



@media(max-width: 767px) {
	.contacts-wrapper {
		text-align: center;
	}
	.contacts {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.contact {
		&:not(:last-child) {
			margin-bottom: 0;
		}
		margin-bottom: 16px;
	}

	.contact__value {
		justify-content: center;
	}

	.map {
		width: 100%;
		height: 300px;
	}
	
}