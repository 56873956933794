.header {
    height: 86px;
    z-index: 100;
    &__wrapper {
        text-align: center;
    }
    .logo {
        img {
            object-fit: contain;
            width: 180px;
            height: 80px;
        }
    }
}

.header_preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #fff;
    z-index: 1001;

    &__image {
        object-fit: contain;
        position: relative;
        top: 50%;
        left: 50%;
        width: 180px;
        height: 80px;
        margin-left: -100px;
        margin-top: -50px;
        animation: preloader-rotate 2s infinite linear;
      }
  }

  .loaded_hiding .header_preloader {
    transition: 0.3s opacity;
    opacity: 0;
  }
  
  .header_loaded .header_preloader {
    display: none;
  }

.navbar {
    background-color: #faf3f3;
    padding: 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    &__list {
        display: flex;
        flex-direction: row; 
        justify-content: center;
        width: 100%;
        position: relative;

        li {
            margin: 0 7px;
            padding: 7px 0;
            line-height: 24px;

            a {
                text-decoration: none;
                color: $color-text;
                font-size: 1.1rem;
                padding:  6px 8px;
                line-height: 26px;

                &:hover{
                    color:$main-color-hover;
                }
            }
        }

        .active>a {
            color:$main-color-hover;
        }
    }

    &__language {
        position: absolute;
        right: 0;

        ul {
            display: flex;
            flex-direction: row; 
            margin: 0 auto; 

            a.qtranxs_image img {
                margin-right: 5px; 
                width: 25px;
                height: 25px;
                object-fit: contain;
            }

            li {
                margin: 0;
                padding: 0;
            }

            a {
                margin: 0;
                padding: 0;
            }
        }
    }

    

    &__lang {
        display: flex;
        flex-direction: row; 
    } 

    .lang {
        width: 25px;
        height: 25px;
        margin-right: 5px; 
    }
}

.red-line {
    line-height: 22px;
    font-weight: 200;
    font-size: 12px;
    background-color: $caption-color;
    z-index: 100;

    &_content {
        display: flex;
        justify-content: space-between;

        a {
            color: #fff;
            text-transform: uppercase;
        }
    }
}

@media(max-width: 1199px) {
    .header {
        &__wrapper {
            height: 50px;
        }
    }

    .header-menu {
        display: none;
    }
    
    .logo {
        width: 110px;
        height: 50px;
    }

    .navbar__list li a {
        padding: 0;
    }
}

@media(max-width: 766px) {
    .navbar {
        display: none;
    }
}
