/*	Хлебные крошки
---------------------------------------*/
.breadcrumbs {
	margin: 24px 0 16px;
	padding: 12px 0;
	ul {
		display: block;
		font-size: 0;
		li {
			display: inline;
			color: #716868;
			font-size: calcFluid(13, 14, 320, 1920);
			font-weight: 400;
			line-height: 1.6;
			a {
				color: #716868;
				position: relative;
				&:hover{
					color: $main-color-hover;
				}

				&:after {
					content: '';
				    position: absolute;
				    opacity: 0.7;
				    bottom: -4px;
				    left: 0;
				    width: 100%;
				    height: 1px;
				    background: #e1d7d7;
				}

				span {
					color: #716868;
				}
			}
			&:after {
				content: '/';
				margin: 0 5px;
				padding: 8px 0;
				color: #cdc9c9;
				font-size: calcFluid(14, 16);
				font-weight: 400;
				line-height: 1.6;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}

			span {
				color: #cdc9c9;
			}
		}
	}
}

/*Страница*/
.page {
	margin-bottom: 72px;

	.likely {
		margin-top: 30px;
		margin-bottom: 18px;
	}
}


/*	Контентная часть текстовой страницы
---------------------------------------*/
.content {

	column-count: 3;
	column-gap: 30px;

	.text-img {
		display: flex;
		padding-top: 10px;
	}

	/* Текст
	---------------------------------------*/
	&-text {
		margin-bottom: 30px;
		width: 100%;
	    break-inside: avoid;
	    page-break-inside: avoid;
		h1 {
			margin-bottom: calcFluid(10, 18, 320, 1920);
			@include font-h1;
		}
		h2 {
			margin-bottom: 16px;
			font-size: calcFluid(15, 24, 320, 1920);
			color: $main-color-hover;
			line-height: calcFluid(19, 26, 320, 1920);
			font-weight: 500;
		}
		h3 {
			padding-top: 10px;
			margin-bottom: 1px;
			@include font-h3;
		}
		p {
			margin-bottom: 16px;
			font-size: 16px;
			line-height: 24px;

			&.ask {
				font-size: 14px;
				color: $main-color-hover;
				font-weight: 500;
				margin-bottom: 8px;
				line-height: 21px;
			}
		}
		a {
			text-decoration: none;
			transition:.2s;
			color: $color-text;
			position: relative;

			&:after {
				content: '';
			    position: absolute;
			    opacity: 0.7;
			    bottom: -4px;
			    left: 0;
			    width: 100%;
			    height: 1px;
			    background: #e1d7d7;
			}
			&:visited{
				color: #7a339e;
			}

			&:hover, &:focus {
				color: $color-text;;
			}
		}
		ol {
			margin-left: 15px;
			padding-top: 0;
			margin-bottom: 20px;
			> li {
				@include font-p;
				list-style-type: decimal;
				padding-bottom: 10px;
				padding-left: calcFluid(5, 15, 320, 1920);
			}
			ul{
				li:last-child{
					padding-bottom: 0;
				}
			}
		}
		ul {
			margin-left: 0;
			padding-top: 0px;
			margin-bottom: 20px;
			padding-left: 40px;
			> li {
				// @include font-p;
				position: relative;
				padding-bottom: 5px;
				display: list-item;
				list-style-type: disc;
			}
		}

		img {
			max-width: 100%;
			width: auto !important;
			height: auto;
			object-fit: contain;
			&.alignleft {
				margin-right: auto;
			}
			&.aligncenter {
				margin: 0 auto;
			}
			&.alignright {
				margin-left: auto;
			}
		}
	}

	/*  Таблица
	---------------------------------------*/
	.table {
		margin-bottom: 30px;
		&__responsive {
			border-radius: 3px;
			width: 100%;
			overflow: auto;
		}
		.for-table {
			@include font-h2;
			padding-top: 0;
			margin-bottom: 17px;
		}
		table {
			width: 100%;
			border: 1px solid #E7E7E7;
			border-collapse: collapse;
		}
	}
	&-text {
		table {
			tr {
				min-height: 50px;
				&:not(:first-child):not(:last-child) {
					border-bottom: 1px solid #E7E7E7;
				}
			}
			th, td {
				@include font-p;
				line-height: calcFluid(20, 24, 320, 1920);
				padding: 12px 20px 14px;
				border: 1px solid #E7E7E7;
			}
			&.table-flex {
				th, td {
					padding: 6px 10px;
					font-size: 14px;
					line-height: 1.4;
					* {
						line-height: 1.4;
						font-size: 14px;
						margin: 0;
						&:not(:last-child) {
							margin-bottom: 4px;
						}
					}
				}
			}
		}
	}

	.gallery {
		box-sizing: border-box;
		// column-count: 2;
		// column-gap: 0;
		position: relative;

		&-panel {
			margin: 0;
			padding-bottom: 25px;

			&_image {
			  max-width: 100%;
			  padding: 0;
			  border: 1px solid #e1d7d7;
			}
		}
	}

	@media (min-width: 768px) {
	  .gallery-panel {
	     break-inside: avoid;
	  }
	}
}



@media(max-width: 991px) {

	.content {
		column-count: 2;
	}

	.page .likely {
		margin-top: 0;
	}
}


@media(max-width: 767px) {
	.breadcrumbs {
		margin-bottom: 0;
	}
	.content {
		column-count: 1;
		/*  Галерея
		---------------------------------------*/
		.gallery {
			width: calc(100% + 10px);
			margin-left: -5px;
			&__link {
				margin: 0 5px 10px;
			}
			&.in-row-6 {
				.gallery__link {
					width: calc(50% - 10px);
				}
			}
			&.in-row-4 {
				.gallery__link {
					width: calc(50% - 10px);
				}
			}
			&.in-row-3 {
				.gallery__link {
					width: calc(50% - 10px);
				}
			}
		}

		iframe {
			width: 100% !important;
			height: auto;
		}
	}
}

@media(max-width: 575px) {
	.content {
		.file {
			width: 100%;
		}
	}
}