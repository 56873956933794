.page {
	.section__head {
		margin-top: 0;
	}
}

.section__head {
	margin-top: calcFluid(0, 32, 320, 1920);
    text-align: center;
    overflow-x: hidden;
    margin-bottom: calcFluid(15, 24, 320, 1920);
}

@media (min-width: 1200px) {
	.section__head {
		margin-bottom: 36px;
	}
}

.section_title, .page-title {
	text-transform: uppercase;
    letter-spacing: 3px;
    font-size: calcFluid(21, 24, 320, 1920);
    color: #1e1a1a;
    font-weight: 500;
    position: relative;
    display: inline-block;
    line-height: 36px;
	
	padding: 0 48px;
		&::after, &::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			height: 1px;
			background-color: #e1d7d7;
			width: 100vw;
		}

		&::after {
			left: 100%;
		}

		&::before {
			right: 100%;
			width: 100vw;
			margin-left: 0;
		}
}

@media (max-width: 575px) {
	.section_title, .page-title {
		padding: 0;

		&::after, &::before {
			display: none;
		}
	}

}